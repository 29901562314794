<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Mechanical Integrity" size="large" />
    <div class="flex flex-wrap" style="max-width: 1150px;">
      <div class="w-full mb-6 ">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph
            ><p>
              Mechanical Integrity is a component of both Process Safety
              Management (PSM) and Risk Management Programs (RMP), to be
              utilized in Regulatory Compliance Audits.
            </p>
            <p>
              SMS works with clients to design a mechanical integrity program to
              the appropriate level of detail. We utilize a risk-based approach
              to mechanical integrity that meets OSHA regulatory requirements
              and establishes a framework for the prioritization of mechanical
              integrity activities.
            </p>
            <p>
              SMS personnel are experienced in the development of complete
              written mechanical integrity programs based on “good engineering
              practice” and plant experience.
            </p>
            <p>
              SMS personnel can assist in the evaluation of inspection and
              testing results, training, and the implementation/configuration of
              a mechanical integrity documentation system. We specialize in
              teaming with client personnel to manage mechanical integrity
              implementation activities at any plant site.
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Mechanical Integrity",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS works with clients to design a risk-based mechanical integrity program that is compliant with all RAGAGEP and OSHA regulatory requirements."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
